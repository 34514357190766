import React, { useEffect, useState } from "react"

// Libraries
import { graphql } from "gatsby"

// Utils
import { colors } from "utils/variables/"

// Layout
import Layout from "layouts/layout-primary"

// Context
import ContextConsumer from "components/context/"

// Components
import Seo from "components/seo/"
import Hero from "components/careers-hero/"

// Sections
import WhoWeAre from "components/careers-who-we-are/"
import Requirements from "components/careers-requirements/"
import Perks from "components/careers-perks/"
import GoodFit from "components/careers-good-fit/"

const SeniorDesigner = props => {
  const {
    data: {
      contentfulJobPost: data,
      spanishPosts: { nodes: spanishPosts },
      englishPosts: { nodes: englishPosts },
    },
  } = props

  const {
    title,
    yearsOfExperience,
    jobDescription,
    aboutTheRole,
    node_locale,
  } = data

  // States
  const [spanishLink, setSpanishLink] = useState(null)
  const [englishLink, setEnglishLink] = useState(null)

  useEffect(() => {
    if (node_locale === "en") {
      setSpanishLink(
        `/careers/${
          spanishPosts[englishPosts.findIndex(post => post.title === title)]
            .slug
        }/`
      )
    } else {
      setEnglishLink(
        `/careers/${
          englishPosts[spanishPosts.findIndex(post => post.title === title)]
            .slug
        }/`
      )
    }
  }, [])

  return (
    <Layout
      menuColor={colors.indiblue}
      englishLink={englishLink}
      spanishLink={spanishLink}
    >
      <ContextConsumer>
        {({ data, set }) => {
          if (data.useYellowFooter) {
            set({
              useYellowFooter: false,
            })
          }
          if (data.darkMenu) {
            set({
              darkMenu: false,
            })
          }
          if (!data.showFooter) {
            set({
              showFooter: true,
            })
          }
          if (data.simplifiedFooter) {
            set({
              simplifiedFooter: false,
            })
          }
        }}
      </ContextConsumer>
      <Seo
        title={title + " at Indicius Design Agency"}
        description="A passionate and experienced design thinker who can tackle complex tasks and transform them into intuitive, accessible, and easy-to-use designs."
      />
      <Hero
        title={title}
        yearsOfExperience={yearsOfExperience}
        jobDescription={jobDescription}
      />
      <WhoWeAre />
      <Requirements data={aboutTheRole} />
      <Perks />
      <GoodFit />
    </Layout>
  )
}

export const query = graphql`
  query($id: String, $language: String!) {
    contentfulJobPost(id: { eq: $id }) {
      title
      node_locale
      yearsOfExperience
      jobDescription {
        raw
      }
      slug
      aboutTheRole {
        raw
      }
    }

    englishPosts: allContentfulJobPost(filter: { node_locale: { eq: "en" } }) {
      nodes {
        title
        slug
      }
    }

    spanishPosts: allContentfulJobPost(filter: { node_locale: { eq: "es" } }) {
      nodes {
        title
        slug
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default SeniorDesigner
