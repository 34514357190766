import { StaticImage } from "gatsby-plugin-image"
import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Icons
import IconCursor from "assets/icons/cursor-drag--midnight.svg"

const StyledCarousel = styled.section`
  display: flex;
  align-items: center;
  margin-top: -64px;
  overflow-x: auto;
  cursor: url("${IconCursor}") 45 45, default;

  ${breakpoint.medium`
    overflow: hidden;
  `}

  &::-webkit-scrollbar {
    display: none;
  }

  .slide {
    flex-shrink: 0;
    display: inline-block !important;
    margin: 0 20px;
    pointer-events: none !important;
    user-select: none;
    opacity: ${props => (props.visible ? "1" : "0")};
    transition: all 0.3s ease-in-out;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:nth-child(2) {
      transition-delay: 0.1s;
    }

    &:nth-child(3) {
      transition-delay: 0.2s;
    }

    &:nth-child(4) {
      transition-delay: 0.3s;
    }

    &:nth-child(5) {
      transition-delay: 0.4s;
    }

    &:nth-child(6) {
      transition-delay: 0.5s;
    }

    &:nth-child(7) {
      transition-delay: 0.6s;
    }
  }
`

class Carousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      interval: null,
      isVisible: false,
      isMouseOver: false,
      isMouseDown: false,
      cursorPosition: 0,
      scrollPosition: 0,
    }
  }

  ref = React.createRef()

  componentDidMount = () => {
    this.playCarousel()
    if (!this.state.isVisible) {
      this.setState({
        isVisible: true,
      })
    }
  }

  componentWillUnmount = () => {
    this.pauseCarousel()
  }

  playCarousel = () => {
    if (window.innerWidth < 1024) {
      const interval = setInterval(() => {
        this.ref.current.scrollLeft = this.ref.current.scrollLeft + 1
      }, 30)

      if (this.state.interval === null) {
        this.setState({
          interval: interval,
        })
      }
    }
  }

  pauseCarousel = () => {
    if (this.state.interval) {
      clearInterval(this.state.interval)
      this.setState({
        interval: null,
      })
    }
  }

  handleMouseOver = () => {
    if (!this.state.isMouseOver) {
      this.setState({
        isMouseOver: true,
      })
    }

    this.pauseCarousel()
  }

  handleMouseLeave = () => {
    if (this.state.isMouseOver) {
      this.setState({
        isMouseOver: false,
      })
    }
    this.playCarousel()
  }

  handleMouseDown = event => {
    const newCursorPosition = event.pageX - this.ref.current.offsetLeft
    const newScrollPosition = this.ref.current.scrollLeft

    this.setState({
      isMouseDown: true,
      cursorPosition: newCursorPosition,
      scrollPosition: newScrollPosition,
    })
  }

  handleMouseUp = () => {
    this.setState({
      isMouseDown: false,
    })
  }

  handleMouseMove = event => {
    if (this.state.isMouseDown) {
      event.preventDefault()
      const x = event.pageX - this.ref.current.offsetLeft
      const walk = x - this.state.cursorPosition
      this.ref.current.scrollLeft = this.state.scrollPosition - walk
    }
  }

  handleVisibility = () => {
    if (!this.state.isVisible) {
      this.setState({
        isVisible: true,
      })
    }
  }

  render = props => (
    <StyledCarousel
      ref={this.ref}
      className={this.props.className}
      visible={this.state.isVisible}
      onMouseDown={this.handleMouseDown}
      onMouseUp={this.handleMouseUp}
      onMouseMove={this.handleMouseMove}
      onMouseOver={this.handleMouseOver}
      onFocus={this.handleMouseOver}
      onMouseLeave={this.handleMouseLeave}
      role="button"
      aria-hidden="true"
    >
      <StaticImage
        className="slide"
        src="../../assets/images/about/about-image-1.png"
        alt=""
        width={404}
        height={269}
        quality={100}
      />
      <StaticImage
        className="slide"
        src="../../assets/images/about/about-image-2.png"
        alt=""
        width={405}
        height={270}
        quality={100}
      />
      <StaticImage
        className="slide"
        src="../../assets/images/about/about-image-3.png"
        alt=""
        width={263}
        height={288}
        quality={100}
      />
      <StaticImage
        className="slide"
        src="../../assets/images/about/about-image-4.png"
        alt=""
        width={210}
        height={316}
        quality={100}
      />
      <StaticImage
        className="slide"
        src="../../assets/images/about/about-image-5.png"
        alt=""
        width={366}
        height={259}
        quality={100}
      />
      <StaticImage
        className="slide"
        src="../../assets/images/about/about-image-6.png"
        alt=""
        width={370}
        height={262}
        quality={100}
      />
      <StaticImage
        className="slide"
        src="../../assets/images/about/about-image-7.png"
        alt=""
        width={210}
        height={316}
        quality={100}
      />
      <StaticImage
        className="slide"
        src="../../assets/images/about/about-image-8.png"
        alt=""
        width={235}
        height={250}
        quality={100}
      />
      <StaticImage
        className="slide"
        src="../../assets/images/about/about-image-9.png"
        alt=""
        width={404}
        height={269}
        quality={100}
      />
      <StaticImage
        className="slide"
        src="../../assets/images/about/about-image-10.png"
        alt=""
        width={211}
        height={316}
        quality={100}
      />
      <StaticImage
        className="slide"
        src="../../assets/images/about/about-image-11.png"
        alt=""
        width={404}
        height={269}
        quality={100}
      />
    </StyledCarousel>
  )
}

export default Carousel
