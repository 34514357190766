import React from "react"

// Libraries
import styled from "styled-components"
import { Trans } from "react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container/"

// Icons
import Icon from "assets/icons/careers/icon-good-fit.inline.svg"

const StyledContainer = styled(Container)`
  width: 100%;
  background-color: ${colors.indiblue};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  ${breakpoint.medium`
    justify-content: space-between;
    flex-wrap: nowrap;
  `}
`

const StyledGoodFit = styled.section`
  padding: 48px 24px 0px 24px;
  background-color: ${colors.indiblue};
  margin: 0px 16px 56px;

  ${breakpoint.small`
    padding: 175px 160px;
    margin: 0;
  `}

  ${breakpoint.medium`
    justify-content: space-between;
    padding: 175px 160px;
    flex-wrap: nowrap;
  `}

  .good-fit__content {
    margin-bottom: 76px;

    ${breakpoint.medium`
      margin-bottom: 0;
    `}
  }

  h2 {
    margin-bottom: 24px;
  }

  a {
    text-decoration: underline;
    z-index: 10;

    &:hover {
      text-decoration: none;
    }

    &::before {
      background-color: ${colors.supernova};
    }
  }

  .good-fit__icon {
    margin-top: 100px;

    ${breakpoint.small`
      margin-top: 0;
    
    `}

    ${breakpoint.medium`
      svg{
        width: 540px;
        height: auto;
      }
    `}
  }
`

const GoodFit = () => (
  <StyledGoodFit>
    <StyledContainer>
      <div className="good-fit__content">
        <h2 className="color--white font-weight--500">
          <Trans>Sounds like a good fit?</Trans>
        </h2>
        <p className="paragraph--small color--midnight font-weight--500">
          <Trans>Send your resume to</Trans>
          <a
            className="strikethrough"
            href="mailto:jobs@indicius.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span> jobs@indicius.com</span>
          </a>
        </p>
      </div>

      <div className="good-fit__icon">
        <Icon />
      </div>
    </StyledContainer>
  </StyledGoodFit>
)

export default GoodFit
