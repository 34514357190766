import React from "react"

// Libraries
import styled from "styled-components"
import { Link } from "gatsby-plugin-react-i18next"
import { Trans } from "react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container/"

// Sections
import Carousel from "sections/about/carousel"

const StyledContainer = styled(Container)`
  max-width: 738px;
`

const StyledWhoWeAre = styled.section`
  padding: 48px 0;

  ${breakpoint.medium`
    padding: 60px 0;
  `}

  h3 {
    margin-bottom: 16px;
  }

  .who-we-are__carousel {
    margin: 40px 0 0 0;
  }

  a {
    &::before {
      background-color: ${colors.supernova};
    }
  }
`

const WhoWeAre = () => (
  <StyledWhoWeAre>
    <StyledContainer>
      <h3 className="color--midnight font-weight--500">
        <Trans>Who we are...</Trans>
      </h3>
      <p className="paragraph--small">
        <Trans>
          We're a group of humans, passionate about what we do and how we do it.
          We appreciate diversity and constantly challenge ourselves to evolve,
          learning from our teammates and pushing ourselves to step out of the
          box.
        </Trans>
      </p>
      <br />
      <p className="paragraph--small">
        <Trans>Check out our </Trans>
        <Link
          to="/about/"
          className="strikethrough color--indiblue color-hover--midnight"
        >
          <Trans> About</Trans>
        </Link>{" "}
        <Trans>and </Trans>
        <Link
          to="/culture/"
          className="strikethrough color--indiblue color-hover--midnight"
        >
          <Trans>Culture</Trans>
        </Link>{" "}
        <Trans>sections if you want to know us better.</Trans>
      </p>
    </StyledContainer>
    <Carousel className="who-we-are__carousel" />
  </StyledWhoWeAre>
)

export default WhoWeAre
