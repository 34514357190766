import React from "react"

// Libraries
import styled from "styled-components"
import { Trans } from "react-i18next"
// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container/"

// Icons
import Icon from "assets/icons/careers/icon-role.inline.svg"

//Contentful rich text plugin
import { renderRichText } from "gatsby-source-contentful/rich-text"
import breakpoints from "utils/breakpoints/"

const StyledContainer = styled(Container)`
  max-width: 738px;
`

const StyledRequirements = styled.section`
  padding: 48px 0;

  ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-left: 0;

    li {
      p {
        display: inline;
        margin-left: -10px;
      }
      ::marker {
        margin: 0 !important;
      }
    }
  }

  ${breakpoint.medium`
    padding: 64px 0;

    li {
      margin-bottom: 32px;
    }
    
  `}

  h3 {
    margin-bottom: 24px;
  }

  h4 {
    color: ${colors.indiblue};
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 16px;
    margin-top: 40px;

    ${breakpoint.medium`
      margin-top: 56px;
    `}

    :nth-of-type(1) {
      margin-top: 24px;
    }
  }

  .req--title {
    margin-top: 40px;

    ${breakpoint.medium`
    margin-top: 56px;

  `}
  }

  p {
    font-size: 18px;
  }

  .requirements__icon {
    margin-top: 40px;
  }

  li {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
  }
`

const Requirements = ({ data }) => (
  <StyledRequirements className="bg--cream color--midnight">
    <StyledContainer>
      <h3 className="font-weight--500">
        <Trans>About the role...</Trans>
      </h3>
      <Trans>{renderRichText(data)}</Trans>
      <div className="requirements__icon">
        <Icon />
      </div>
    </StyledContainer>
  </StyledRequirements>
)

export default Requirements
