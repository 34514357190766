import React from "react"

// Libraries
import styled from "styled-components"
import { Trans } from "react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables"

// Components
import Container from "components/container/"

//Contentful rich text plugin
import { renderRichText } from "gatsby-source-contentful/rich-text"

const StyledContainer = styled(Container)`
  max-width: 738px;
`

const StyledHero = styled.section`
  width: 100%;
  min-height: 480px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 105px 0 80px 0;

  ${breakpoint.medium`
    min-height: 570px;
    padding: 150px 0 144px 0;
  `}

  .hero__subtitle {
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  .hero__title {
    margin-bottom: 32px;

    ${breakpoint.medium`
      margin-bottom: 48px;
  `}
  }

  .hero__description {
    .exp__years {
      margin-bottom: 24px;
    }

    a {
      &:hover {
        color: ${colors.supernova};
        transition: all 0.3s;
      }
    }

    .description {
      p {
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`

const Hero = ({ title, yearsOfExperience, jobDescription }) => (
  <StyledHero className="bg--indiblue color--white">
    <StyledContainer>
      <h4 className="hero__subtitle color--supernova font-weight--700">
        <Trans>What we're looking for...</Trans>
      </h4>
      <h1 className="hero__title">{title}</h1>

      <div className="hero__description">
        <p className="exp__years color--midnight">{yearsOfExperience}</p>
        <div className="description">{renderRichText(jobDescription)}</div>

        <br />
        <br />
        <p className="color--midnight">
          <Trans>Send your resume to</Trans>
          <a
            href="mailto:jobs@indicius.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            jobs@indicius.com
          </a>{" "}
          <Trans> and we can chat.</Trans>
        </p>
      </div>
    </StyledContainer>
  </StyledHero>
)

export default Hero
