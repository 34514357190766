import React from "react"

// Libraries
import styled from "styled-components"
import { Trans } from "react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

//Grid
import Grid from "components/grid/"

// Components
import Container from "components/container/"

// Icons
import Money from "assets/icons/careers/money-growth.inline.svg"
import Health from "assets/icons/careers/health.inline.svg"
import FlexibleHours from "assets/icons/careers/flexible-hours.inline.svg"
import Remote from "assets/icons/careers/remote.inline.svg"
import Professional from "assets/icons/careers/professional.inline.svg"
import Birthday from "assets/icons/careers/birthday.inline.svg"
import English from "assets/icons/careers/english.inline.svg"
import Coin from "assets/icons/careers/perk-icon.inline.svg"

const StyledContainer = styled(Container)`
  max-width: 738px;

  ${breakpoint.small`
    padding-left: 10px !important;
    padding-right: 10px !important;
    
  `}
`

const StyledPerks = styled.section`
  background-color: ${colors.white};
  padding: 48px 0;
  color: ${colors.midnight};

  h3 {
    margin-bottom: 32px;
  }

  p {
    font-size: 20px;
  }

  .perk {
    padding: 24px 0;
    border-bottom: 1px solid ${colors.cloudy};
    min-height: 149px;

    &:first-of-type {
      padding-top: 0;
    }

    svg {
      display: block;
      margin-bottom: 16px;
    }

    &--last {
      padding-bottom: 22px;
    }
  }

  .icon-coin {
    svg {
      * {
        stroke: ${colors.indiblue};
      }
    }
  }
`

const Perks = props => (
  <StyledPerks>
    <StyledContainer>
      <h3 className="font-weight--500">
        <Trans>What we offer...</Trans>
      </h3>
      <Grid columns="2" gutter="32px">
        <div className="grid__item">
          <div className="perk ">
            <Money />
            <p>
              <Trans>
                Competitive compensation, based on your skills and experiences
              </Trans>
            </p>
          </div>

          <div className="perk">
            <Health />
            <p>
              <Trans>Health insurance coverage</Trans>
            </p>
          </div>

          <div className="perk">
            <FlexibleHours />
            <p>
              <Trans>Flexible hours</Trans>
            </p>
          </div>

          <div className="perk">
            <Remote />
            <p>
              <Trans>Remote-mode benefits</Trans>
            </p>
          </div>
        </div>

        <div className="grid__item">
          <div className="perk">
            <Professional />
            <p>
              <Trans>Annual budget for personal and academic development</Trans>
            </p>
          </div>
          <div className="perk">
            <Birthday />
            <p>
              <Trans>Birthday Gift + Day Off</Trans>
            </p>
          </div>
          <div className="perk">
            <English />
            <p>
              <Trans>English classes</Trans>
            </p>
          </div>
          <div className="perk perk--last icon-coin">
            <Coin role="img" focusable="false" />
            <p>
              <Trans>Incentive bonus</Trans>
            </p>
          </div>
        </div>
      </Grid>
    </StyledContainer>
  </StyledPerks>
)

export default Perks
